
    
    <template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-if="!loading" my-2>
            <form @submit.prevent="update_item_images" autocomplete="off">
                <v-layout row wrap>
                    

            <v-flex xs12 lg2 xl2 md3 sm4>
            <v-select class="mx-1" clearable rounded filled :items="items" v-model="item_images.item_id" outlined  item-text="item_id"
                            item-value="item_id" :return-object="false" :label="$store.getters.lang.data.item_id">
                        </v-select>
                        </v-flex>
            
        <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field rounded type="text" v-model="item_images.item_image_path" :label="$store.getters.lang.data.item_image_path"
                            outlined class="mx-1" filled  required>
                        </v-text-field>
                    </v-flex>
        

    
    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="primary" rounded class="mx-1"  type="submit" x-large>{{$store.getters.lang.data.update_btn}}</v-btn>
                    </v-flex>    
                </v-layout>
            </form>
        </v-container>
        
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>

<script>
    import controller from './../../controller/item_images'
    export default {
        data() {
            return {
                item_images: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },

            }
        },
        computed: {
            
                    items (){
                        return this.$store.getters.items
                    },
                    
            
        },
        methods: {
            get_item_images() {
                this.loading = true
                controller.getOne(this.id).then(r =>{
                    this.item_images = r.data.row
                    this.loading = false
                }).catch(e =>{
                    console.log(e);
                })
            },
            update_item_images() {
                controller.update_item_images(this.item_images).then(r => {
                    if (r.data) {
                        this.snackbar = {
                            value: true,
                            text: 'Updated',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Not Updated',
                            color: 'error'
                        }
                    }
                })
            },
            
        },
        mounted() {
            this.id = this.$route.params.id
            this.get_item_images()
        },
    }
</script>
    
    